/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-beltone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-beltone",
    "aria-label": "what is beltone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is Beltone?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Beltone is a network of 1500 hearing aid dispensing offices that exclusively offer that ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "brand of hearing aids"), ". If you buy a hearing aid from a Beltone practice, you cannot go outside for Beltone network for service or programming. Beltone practices cover designated geographical areas which protects the hearing care professional from competition within the area."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sam and Faye Rosen founded Beltone in Chicago in 1940. They engineered ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " in order to help a friend who had hearing loss. In 1950, Beltone introduced a behind-the-ear model hearing aid. The first Beltone in-the-ear hearing aid came out in the 1960’s. Digital technology debuted in the 1990’s."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the 2010’s, Beltone first offered wireless streaming from accessories into their hearing aids using 2.4 GHz technology. These accessories helped with phone calls and TV listening. Beltone followed these advances with the Made for iPhone hearing aid concept. Made for ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/iphone/",
    className: "c-md-a"
  }, "iPhone hearing aids"), " can stream sound from Apple devices into hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Technology from Beltone and ReSound tend to overlap a great deal. GN Store Nord, the parent company, bought ReSound in 1999 and Beltone in 2000. Unlike Beltone, ReSound hearing aids are offered by independent hearing care professionals."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Beltone hearing aid technology addresses the needs of the most common types of hearing loss. The company does not offer wireless CROS hearing aids that some hearing care professionals prescribe for people with unilateral hearing loss. Beltone hearing aids also do not directly interface with cochlear implants. In contrast, ReSound hearing aids work directly with some cochlear implant models."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-to-expect-at-beltone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-to-expect-at-beltone",
    "aria-label": "what to expect at beltone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What to Expect at Beltone"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Beltone hearing care professionals have received general training in ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing testing"), " and hearing aids in the course of obtaining their state licenses. In addition, they attend regular online courses and in-person workshops dedicated to Beltone technology."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Beltone practices do not charge for hearing testing or initial consultation appointments. In addition, unless the hearing aids are purchased through a specific agreement with a health insurance company, the hearing aids come with a lifetime support guarantee. Any practice in the Beltone network will adjust the hearing aids and perform in-office repairs. These services come at no charge for the appointments. Customers may pay for the cost of replacement parts. There may be charges for hearing aid repairs at the factory once the manufacturer warranty has expired."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The bundled pricing model at Beltone means that customers pay up front for the device and a lifetime of service appointments. If a customer buys ReSound hearing aids from an independent practice, the practice may choose to charge separately for the device and for appointments. The customer can take the ReSound hearing aids to any other practice for programming and service but may pay for the appointment."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "most-recent-product-news",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#most-recent-product-news",
    "aria-label": "most recent product news permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Most Recent Product News"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Beltone introduced the Beltone Amaze hearing aid in 2018. This line offers a lithium-ion rechargeable battery in a receiver-in-the-ear (RIC) product. According to Beltone, each charge lasts 30 hours. Consumers may also select a model with a size 13 disposable battery."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Beltone Amaze pairs with the Hear Max app on smart phones for control of volume and programs. Consumers may request assistance through the app’s Remote Care option. Hearing care providers make adjustments and send them back to the customer."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ask-us-any-question-about-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ask-us-any-question-about-hearing-aids",
    "aria-label": "ask us any question about hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ask Us Any Question About Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we know that the hearing aid industry can be confusing to consumers. That’s why we offer free consultations over the phone with our hearing experts. Just register at hear.com, and one of our experts will call you to answer your questions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you decide that you would like to try hearing aids, we will refer you to one of our hearing care professional partners for a hearing evaluation and device consultation. The hearing care professional will fit your hearing aids and provide ongoing service."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids purchased through hear.com have a 45-day trial period. You get your money back if you’re not satisfied with your hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We do not offer Beltone hearing aids. Instead, our providers offer a range of products from many different manufacturers. They have the flexibility and independence to recommend the best device for you."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the latest hearing aids",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
